// tslint:disable: member-ordering
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, EMPTY, firstValueFrom, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
import { ControledByMaestro } from './maestro.service';
import { CustomerService } from './customer.service';
import { CompanyService } from './company.service';
import { Logger } from './logger.service';
import { ResponseObj } from '@app/@core/models/response.model';
import * as moment from 'moment-timezone';
import { ActivatedRoute, Router } from '@angular/router';
import { fromUTC, isDateOnSchedule, notNull, isAddressValid } from '@app/@shared/util-functions';
import * as UAParser from 'ua-parser-js';
import { Store } from '../models/store/store.model';
import { GuestEditOrderObject, IOrder, Order, OrderStatus, OrderType } from '../models/order/order.model';
import { Discount } from '../models/discount/discount.model';
import { IAddress } from '../models/address.model';
import { Customer, ICustomer } from '../models/customer/customer.model';
import { StoreUtils } from '../models/company/store-utils.model';
import { IItem, Item } from '../models/item/item.model';
import { CustomerVehicle, IVehicle } from '../models/vehicle.model';
import { IPayments } from '../models/payments.model';
import { OrderAggregatorService } from './order-agreggator.service';
import { AuthService } from './auth.service';
import { DiscountService } from './discount.service';
import { IAppliedServiceCharge } from '../models/applied-service-charge.model';

export const orderKey = 'order';

const logger = new Logger('OrderService');

interface InitOptions {
  authenticated: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class OrderService implements ControledByMaestro<InitOptions>, OnDestroy {
  constructor(
    private http: HttpClient,
    private customerService: CustomerService,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private aggregatorService: OrderAggregatorService
  ) {}

  private readonly _orderList = new BehaviorSubject<Order[] | null>(null);
  private readonly _currentOrder = new BehaviorSubject<Order | null>(null);
  private readonly _validatingOrder = new BehaviorSubject<boolean>(false);
  private readonly _synchronizingOrder = new BehaviorSubject<boolean>(false);

  public static serviceCharges: IAppliedServiceCharge[] = [];
  private authenticated = false;
  private storageKey: string | null = null;
  private validate$: Subject<Order> = new Subject<Order>();
  private validateSub?: Subscription;
  private toggleFavoriteOnAddItem = false;
  private subs: Subscription = new Subscription();
  public storeUtils: StoreUtils;
  public currentOrderType: OrderType | null = null;
  public isEdit = false;
  public startAggregator = false;
  public aggregatedOrders: string[] = [];
  public loadingMenusFromAggregator = false;
  public saveGuestEmailForPromotions = false;
  public readonly orderList$ = this._orderList.asObservable();
  public readonly currentOrder$ = this._currentOrder.asObservable();
  public readonly validatingOrder$ = this._validatingOrder.asObservable();
  public readonly synchronizingOrder$ = this._synchronizingOrder.asObservable();

  public get currentOrder() {
    return this._currentOrder.getValue();
  }

  public get orderList() {
    return this._orderList.getValue();
  }

  public get validatingOrder() {
    return this._validatingOrder.getValue();
  }

  public get synchronizingOrder() {
    return this._synchronizingOrder.getValue();
  }

  private readonly _initiated = new BehaviorSubject<boolean>(false);
  public async init({ authenticated }: InitOptions) {
    this.subs.add(
      this.route.queryParams.subscribe(async (params) => {
        if (params.currentOrderType) {
          this.setCurrentOrderType(params.currentOrderType);
        }
      })
    );
    logger.info(`Init Order - Authenticated: ${authenticated}`);
    this.listenToChangeAndValidate();
    const localOrderForCurrentMenu = this.getOrderInLocalStorage();
    this.authenticated = authenticated;
    this.loadingMenusFromAggregator = true;
    if (this.authService.authenticatedAsGuest) {
      this._orderList.next([]);
    }
    if (authenticated) {
      if (localOrderForCurrentMenu) {
        if (localOrderForCurrentMenu.id) {
          logger.info('Local valid found');
          this.setCurrentOrder(
            new Order({
              ...localOrderForCurrentMenu,
              ...(this.currentOrder ? this.currentOrder : {}),
            } as IOrder)
          );
          if (this.authService.authenticatedAsGuest) {
            this._orderList.next([]);
          }
        } else {
          logger.info('Local invalid found, synchronizing');
          this._synchronizingOrder.next(true);

          const mergeAndSetCurrentOrder = (order: IOrder, localOrder: IOrder) => {
            const items = [...(order.items || []), ...(localOrder.items || [])];
            this.setCurrentOrder(
              new Order({
                ...order,
                ...localOrder,
                id: order.id,
                items,
              })
            );
          };

          if (this.authService.authenticatedAsGuest) {
            if (!this.isEdit) {
              logger.info('Open order not found, creating new order and merging');
              const newOrder = await this.createOrder();
              mergeAndSetCurrentOrder(newOrder, localOrderForCurrentMenu);
            }
            this._synchronizingOrder.next(false);
            this._orderList.next([]);
          } else {
            this.subs.add(
              this.orderList$
                .pipe(
                  filter(() => this.currentOrder === null),
                  filter(notNull)
                )
                .subscribe(async (ol) => {
                  if (!localStorage.getItem('reorderId')) {
                    const openOrder = ol.find((o) => o.isAValidOpenOrder());
                    if (openOrder) {
                      logger.info('Open order found, merging');
                      mergeAndSetCurrentOrder(openOrder, localOrderForCurrentMenu);
                    } else {
                      logger.info('Open order not found, creating new order and merging');
                      const newOrder = await this.createOrder();
                      mergeAndSetCurrentOrder(newOrder, localOrderForCurrentMenu);
                    }
                  }

                  this._synchronizingOrder.next(false);
                })
            );
          }
        }
      } else {
        logger.info('Local order not found, creating a new one');
        this.setCurrentOrder(await this.createOrder());
      }
    } else {
      this._orderList.next([]);
      if (localOrderForCurrentMenu) {
        logger.info('Local order found');
        this.setCurrentOrder(new Order(localOrderForCurrentMenu));
      } else {
        logger.info('Local order not found, creating a new one');
        await this.createLocalOrder();
      }
    }
    if (this.currentOrder && this.currentOrder.items?.length) {
      const storeUtils = await firstValueFrom(this.companyService.currentStoreUtils$.pipe(first()));
      this.storeUtils = storeUtils;
      const asapAndDateAsked = this.getDefaultOrderType(storeUtils, this.currentOrder);
      this.setCurrentOrder(new Order({ ...this.currentOrder, ...asapAndDateAsked } as IOrder));
      this.setCurrentOrderDevice();
    }
    window.onbeforeunload = () => this.ngOnDestroy();
    const reorderId = localStorage.getItem('reorderId');
    if (reorderId) {
      this.subs.add(
        this.orderList$.pipe(filter(notNull)).subscribe((ol) => {
          const order = ol.find((o) => o.id === reorderId);
          if (order) {
            this.loadOrder(order.id);
            localStorage.removeItem('reorderId');
          }
        })
      );
    }
    if (this.companyService.currentCompany?.groupId) {
      const hasItemFromDifferentLocation = this.currentOrder?.items.some(
        (item) => item.store_id && item.store_id.toString() !== this.companyService.currentStore?.id.toString()
      );
      const hasCartInCookie = document.cookie.includes('bbweb-cart');
      if (hasCartInCookie || hasItemFromDifferentLocation) {
        this.startAggregator = true;
      }
      if (hasCartInCookie) {
        await this.setOrderFromCookieIfFound();
      } else if (hasItemFromDifferentLocation) {
        this.saveOrderToCookieAndStartAggregator();
      }
    }
    this.loadingMenusFromAggregator = false;
    this.validate$.next(this.currentOrder as Order);
    this._initiated.next(true);
  }

  public loadOrder(id: string, markAsFavorite: boolean = false) {
    let order = this.orderList?.find((o) => o.id === id);
    if (order) {
      order = new Order(order);
      const itemErrors: string[] = [];
      const validItems = this.companyService.currentMenu?.items.map((item) => item.id);
      const itemsToLoad: Item[] = [];
      order.items.forEach((item: IItem) => {
        if (validItems?.includes(item.item_id)) {
          let hasErrors = false;
          item.modifiers.forEach((mod: IItem) => {
            if (!validItems.includes(mod.item_id)) {
              hasErrors = true;
              itemErrors.push(mod.name);
            }
          });
          if (!hasErrors) {
            itemsToLoad.push(item);
          }
        } else {
          itemErrors.push(item.name);
        }
      });
      if (itemErrors.length > 0) {
        this.toastr.info(
          `The following items weren't available: ${itemErrors
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(', ')}`
        );
      }
      const newOrder = new Order({
        ...(this.currentOrder ?? order),
        items: itemsToLoad,
        IdempotencyKey: `${new Date().toISOString()}-${Math.random()}`,
      } as IOrder);
      if (itemsToLoad.length && markAsFavorite) {
        newOrder.is_favorite = true;
        this.toggleFavoriteOnAddItem = true;
      }
      this.setCurrentOrder(newOrder);
    }
  }

  public addItem(item: IItem) {
    if (this.currentOrder && this.currentOrder.items && this.companyService.currentStore) {
      this.currentOrder?.addItem(item, this.toggleFavoriteOnAddItem);
      this.setCurrentOrder(this.currentOrder);
      const { getAsapAvailable } = this.companyService.getStoreUtils(this.companyService.currentStore);
      const { prepTime: currentPrepTime } = getAsapAvailable(this.currentOrder.order_type, this.currentOrder);
      if (this.currentOrder.is_asap) {
        const { prepTime: newPrepTime, available } = getAsapAvailable(this.currentOrder.order_type, this.currentOrder);
        if (newPrepTime && currentPrepTime && newPrepTime > currentPrepTime) {
          this.toastr.info(
            `Your waiting time has been increased by ${Math.ceil(
              newPrepTime - currentPrepTime
            )} minutes due to this item: <br /> ${item.name}`,
            undefined,
            { enableHtml: true }
          );
        } else if (!available) {
          this.toastr.info(`The item: ${item.name} is not available for asap orders, please schedule a date.`);
          setTimeout(() => {
            this.router.navigate([{ outlets: { modal: 'current-order-details' } }]);
          });
        }
      }
    }
  }

  public deleteItem(item_id: string, idx: number) {
    if (this.currentOrder) {
      this.currentOrder.deleteItem(item_id, idx);
      this.setCurrentOrder(this.currentOrder);
    }
  }

  public clearOrder() {
    if (this.currentOrder) {
      this.currentOrder.clearOrder();
      this.validate$.next(this.currentOrder);
    }
  }

  public applyDiscount(discount?: Discount) {
    if (this.currentOrder) {
      this.currentOrder?.applyDiscount(discount);
      this.validate$.next(this.currentOrder);
    }
  }

  public removeDiscount(discount?: Discount) {
    if (this.currentOrder) {
      this.currentOrder?.removeDiscount(discount);
      this.validate$.next(this.currentOrder);
    }
  }

  public removeDiscounts() {
    if (this.currentOrder) {
      this.currentOrder?.removeDiscounts();
      this.validate$.next(this.currentOrder);
    }
  }

  public toggleCurrentOrderFavorite() {
    this.currentOrder?.toggleFavorite();
  }

  public toggleFavorite(order: Order) {
    return this.http
      .post(`order/${order.id}/favorite`, {})
      .pipe(
        switchMap(() => this.getOrderList(false)),
        filter(notNull),
        map((ol) => {
          this._orderList.next(ol.map((o) => new Order(o)));
        }),
        catchError((err) => {
          this.toastr.error(`Problem ${order.is_favorite ? 'un' : ''}marking order as favorite`);
          return of(err);
        })
      )
      .toPromise();
  }

  public setCurrentOrderDate(date: string, is_asap: boolean = false) {
    if (this.currentOrder) {
      const newOrder = new Order({
        ...this.currentOrder,
        is_asap,
        date_asked: date,
        scheduled_time: date,
        delivery_fee: 0,
      } as IOrder);
      this.setCurrentOrder(newOrder);
    }
  }

  public setCurrentOrderTipAmount(tip: number) {
    this.currentOrder?.setTipAmount(tip);
    this.setCurrentOrder(this.currentOrder);
  }

  public setCurrentOrderType(order_type: OrderType) {
    this.currentOrderType = order_type;
    const zones = this.companyService.currentStore?.zones;
    const customer = this.customerService.customer ?? undefined;
    this.currentOrder?.setCurrentOrderType(order_type, customer, zones);
    this.setCurrentOrder(this.currentOrder);
  }

  private setDeliveryServiceCharges(order: IOrder) {
    const zones = this.companyService.currentStore?.zones;
    if (order) {
      this.currentOrder?.setDeliveryServiceCharges(zones);
    }
  }

  public setCurrentOrderCustomer(customer?: ICustomer) {
    if (this.currentOrder) {
      this.currentOrder?.setCustomer(customer);
      this.validate$.next(this.currentOrder);
    }
  }

  public setCurrentOrderTableNumber(party_table: string) {
    if (this.currentOrder) {
      this.currentOrder?.setTableNumber(party_table);
      this.validate$.next(this.currentOrder);
    }
  }

  public setCurrentOrderVehicle(vehicle: IVehicle) {
    if (this.currentOrder) {
      this.currentOrder?.setVehicle(new CustomerVehicle(vehicle));
      this.validate$.next(this.currentOrder);
    }
  }

  public setPendingPayment(pendingPayment: boolean) {
    if (this.currentOrder) {
      this.currentOrder.setPendingPayment(pendingPayment);
      this.validate$.next(this.currentOrder);
    }
  }

  public async setCurrentOrderAddress(address: IAddress) {
    if (this.currentOrder) {
      this.currentOrder.setAddress(address);
      const storeUtils = await firstValueFrom(this.companyService.currentStoreUtils$.pipe(first()));
      const { asapAndDateAsked, available } = this.currentOrder.isOrderTypeAvailable(
        OrderType.DELIVERY,
        storeUtils,
        this.currentOrder
      );
      this.currentOrderType = this.currentOrder.order_type;
      if (available) {
        const newOrder = { ...this.currentOrder, ...asapAndDateAsked };
        this.setCurrentOrder(new Order(newOrder as IOrder));
      } else {
        this.toastr.warning("Couldn't change your order type right now, delivery is not available at the moment.");
      }
    }
  }

  public getOrderList(hot: boolean = true) {
    const storeId = this.companyService.currentStore?.id;
    const customerId = this.customerService.customer?.Id;
    const companyId = this.companyService.currentCompany?.company_id;

    if (storeId != null && customerId != null && companyId != null) {
      const post = this.http
        .post(`order/listorders/${storeId}/${customerId}/${companyId}`, {})
        .pipe(switchMap((response: any) => of(response.Data as Order[])));

      if (hot) {
        this.subs.add(
          post.subscribe((ol) => {
            this._orderList.next(ol.map((o) => new Order(o)));
          })
        );
      }
      return post;
    } else {
      console.error('One or more parameters are undefined or null:', { storeId, customerId, companyId });
      return of(null);
    }
  }

  public getOrderDiscount(): number {
    const discount = this.currentOrder?.getOrderDiscount();
    return discount ?? 0;
  }

  public getOrder(orderId: string) {
    const post = firstValueFrom(
      this.http.post<ResponseObj<GuestEditOrderObject>>(`guest/get-order-and-token/${orderId}`, {})
    )
      .then((response) => {
        return response.Data;
      })
      .catch((err) => {
        logger.error(err);
        this.toastr.error('We had a problem getting your order info', 'Oops!');
        return Promise.reject();
      });
    return post;
  }

  public shouldUseAggregator() {
    const storesInCart = new Set();
    this.currentOrder?.items.forEach((item) => {
      if (item.store_id) {
        storesInCart.add(item.store_id);
      }
    });
    // Only use aggregator if the user has more than one store in their cart
    return storesInCart.size > 1;
  }

  public async submitOrder(paymentNonce: string, CardType: string, edit: boolean = false) {
    let submittedOrderId = this.currentOrder?.id;
    if (this.currentOrder && this.isValidStore(this.companyService.currentStore)) {
      if (!this.currentOrder.isInAValidStatus()) {
        this.toastr.info('Problem validating cart, try it again later!');
        return Promise.reject();
      }
      const route = edit
        ? `order/edit/${this.companyService.currentStore.id}`
        : this.shouldUseAggregator()
        ? 'order/super'
        : `order/${this.companyService.currentStore.id}`;
      try {
        const response = await firstValueFrom(
          this.http.post(`${route}`, {
            ...this.currentOrder,
            CompanyGroupId: this.companyService.currentCompany?.groupId,
            device: this.getDevice(),
            date_placed: moment.utc().format(),
            checks: [
              {
                AppliedServiceCharges: this.currentOrder.checks?.[0]?.AppliedServiceCharges ?? null,
                Selections: this.currentOrder.getSelectionWithDiscount(this.currentOrder),
                Customer: this.customerService.customer,
                AppliedDiscounts: this.currentOrder.getCheckDiscounts(
                  this.companyService.currentStore?.id,
                  this.companyService.currentCompany?.company_id
                ),
                Payments: [
                  {
                    TipAmount: this.currentOrder.tip,
                    Amount: this.currentOrder.total,
                    CardPayment: paymentNonce,
                    CardType,
                  },
                ],
              },
            ],
          })
        );
        if (this.shouldUseAggregator() && (response as any)?.OrdersId) {
          this.aggregatedOrders = (response as any)?.OrdersId;
          submittedOrderId = (response as any).id;
        }
        this.toastr.success('Order submitted!');
        if (!this.authService.authenticatedAsGuest) {
          this.setCurrentOrder(await this.createOrder());
          this.getOrderList();
        } else {
          this._orderList.next([this.currentOrder as Order]);
          this.createLocalOrder();
          this.authenticated = false;
        }
        return Promise.resolve(submittedOrderId);
      } catch (err: any) {
        logger.error(err);
        this.toastr.error(
          err?.error?.Data ?? 'We had a problem submitting your order, please try it again later.',
          'Oops!'
        );
        return Promise.reject();
      }
    } else {
      this.toastr.info('This store is currently paused or disabled, please try it again later!');
      return Promise.reject('PAUSED');
    }
  }

  public isValidStore(currentStore: Store | null): currentStore is Store {
    return !currentStore?.is_paused && !currentStore?.is_disabled ? true : false;
  }

  public closeCurrentOrder() {
    this.startAggregator = false;
    this.setCurrentOrder(null);
  }

  public setEditOrder(order: Order) {
    this.subs.add(
      this.companyService.currentMenu$
        .pipe(
          filter(notNull),
          switchMap((menu) =>
            this.http.get<Discount[]>(`discount/${this.companyService.currentStore?.id}`).pipe(
              filter(notNull),
              map((discountList) => ({ discountList, menu }))
            )
          )
        )
        .subscribe(({ menu, discountList }) => {
          localStorage.setItem(orderKey + '-' + menu.storeId, JSON.stringify(order));
          const discounts = new Set();
          if (order.checks?.[0]?.AppliedDiscounts?.length) {
            order.checks[0].AppliedDiscounts.forEach((d) => discounts.add(d.Discount.Guid));
          }
          if (order.checks?.[0]?.Selections?.length) {
            order.checks[0].Selections.forEach((item) => {
              if (item.AppliedDiscounts?.length) {
                item.AppliedDiscounts.forEach((d) => discounts.add(d.Discount.Guid));
              }
            });
          }
          order.appliedDiscounts = [];
          discounts.forEach((guid) => {
            const discount = discountList.find((d) => d.guid === guid);
            if (discount !== undefined) {
              order.appliedDiscounts?.push(discount);
            }
          });
          this.subs.add(
            this._initiated.pipe(filter((init) => init === true)).subscribe(() => {
              this.setCurrentOrder(order);
            })
          );
        })
    );
  }

  public resetOrder() {
    if (this.storageKey) {
      if (this.startAggregator) {
        this.aggregatorService.removeOrderFromCookie();
      }
      localStorage.removeItem(this.storageKey);
      this.storageKey = null;
      window.location.href =
        window.location.protocol + '//' + window.location.host + '/' + this.companyService.currentStore
          ? this.companyService.currentStore?.url_path + '/menu'
          : '/';
    }
  }

  public async validateCurrentOrder() {
    let orderTypeValid;
    let dateAndTimeValid;
    let menuScheduleValid;
    let customerValid = true;
    let message;
    let missingAddress = false;
    let openOrderDetails = false;

    // Obter a lista de pedidos e opções de pedido
    const orders = await this.orderList$.pipe(first()).toPromise();
    const orderOptions = await this.companyService.currentStore$
      .pipe(
        filter(notNull),
        map((store) => store?.order_options?.filter((op) => op.enabled && !op.is_deleted) ?? [])
      )
      .pipe(first())
      .toPromise();

    // Obter a data da order atual
    const orderDateAsked = this.currentOrder?.date_asked
      ? new Date(this.currentOrder.date_asked).toISOString().split('T')[0]
      : null;

    // Lógica para validar se o pedido na data específica é permitido
    const ordersOnDate = new Set();
    orders?.forEach((order) => {
      const orderDate = new Date(order?.date_asked).toISOString().split('T')[0];
      if (orderDate === orderDateAsked) ordersOnDate.add(order?.order_type);
    });

    const currentOrderOption = this.storeUtils
      .getOrderOptions()
      ?.find((option) => option.order_type === this.currentOrder?.order_type);

    if (currentOrderOption && !currentOrderOption.same_day && ordersOnDate.has(this.currentOrder?.order_type)) {
      orderTypeValid = false;
      message = 'Same day orders are not allowed for this order type.';
    } else {
      switch (this.currentOrder?.order_type) {
        case OrderType.PICKUP:
        case OrderType.OTHER:
        case OrderType.CATERING:
          this.currentOrder?.removeDeliveryServiceCharges();
          orderTypeValid = true;
          break;
        case OrderType.DELIVERY:
          if (this.currentOrder?.address?.line1 && this.currentOrder?.address?.line2) {
            this.setDeliveryServiceCharges(this.currentOrder);
            if (
              this.companyService.currentStore &&
              this.currentOrder?.address &&
              (await isAddressValid(
                this.currentOrder as IOrder & { address: IAddress },
                this.companyService.currentStore.id,
                this.http
              ))
            ) {
              orderTypeValid = true;
            } else {
              orderTypeValid = false;
              missingAddress = true;
              message = 'Please, select another delivery address';
            }
          } else {
            orderTypeValid = false;
            missingAddress = true;
            message = 'Please, select a delivery address';
          }
          break;
        case OrderType.CURBSIDE:
          this.currentOrder?.removeDeliveryServiceCharges();
          if (
            this.currentOrder?.vehicle?.make &&
            this.currentOrder?.vehicle?.model &&
            this.currentOrder?.vehicle?.color
          ) {
            orderTypeValid = true;
          } else {
            orderTypeValid = false;
            openOrderDetails = true;
            message = 'Please, fill in your vehicle info';
          }
          break;
        case OrderType.DINE_IN:
          this.currentOrder?.removeDeliveryServiceCharges();
          if (this.currentOrder?.party_table) {
            orderTypeValid = true;
          } else {
            orderTypeValid = false;
            openOrderDetails = true;
            message = 'Please, fill in the table number';
          }
          break;
        default:
          if (this.currentOrder) {
            this.currentOrder.removeDeliveryServiceCharges();
          }
          orderTypeValid = false;
      }
    }

    if (orderTypeValid) {
      if (this.companyService.currentStore && this.currentOrder) {
        const { getAsapAvailable, getValidTimes } = this.companyService.getStoreUtils(this.companyService.currentStore);
        if (this.currentOrder?.is_asap) {
          const { available, prepTime } = getAsapAvailable(this.currentOrder?.order_type, this.currentOrder);
          if (available) {
            dateAndTimeValid = true;
            this.setCurrentOrderDate(moment.utc().add(prepTime, 'minutes').format(), true);
          } else {
            dateAndTimeValid = false;
            openOrderDetails = true;
            message = "You can't order for asap now, please schedule a date and time for your order.";
          }
        } else {
          const day = fromUTC(
            this.companyService.currentStore?.time_zone_offset_minutes,
            this.currentOrder?.date_asked
          );
          const time = day.format('hh:mm A');
          const { validTimes, error } = getValidTimes(day, this.currentOrder?.order_type, this.currentOrder);
          if (validTimes?.includes(time)) {
            dateAndTimeValid = true;
          } else {
            dateAndTimeValid = false;
            openOrderDetails = true;
            message = `${error ? error : ''} Please, select another date and time.`;
          }
        }
      }
    }

    if (orderTypeValid && dateAndTimeValid) {
      ({ valid: menuScheduleValid, message } = this.validateItemsOnSchedule());
    }

    if (orderTypeValid && dateAndTimeValid && menuScheduleValid) {
      const customer = this.currentOrder?.customer;
      if (!customer || !customer?.FirstName) {
        customerValid = false;
        message = 'Please, fill your info!';
      } else {
        customerValid = true;
      }
    }

    if (!orderTypeValid || !dateAndTimeValid || !menuScheduleValid || !customerValid) {
      if (message) {
        this.toastr.warning(message, undefined, { enableHtml: true });
      }

      if (missingAddress) {
        this.router.navigate([{ outlets: { modal: 'addresses' } }], { queryParams: { origin: this.router.url } });
      } else if (openOrderDetails) {
        this.router.navigate([{ outlets: { modal: 'current-order-details' } }]);
      } else if (!customerValid) {
        this.router.navigate([
          { outlets: { modal: this.authService.authenticatedAsGuest ? 'guest-checkout' : 'account' } },
        ]);
      }
      return false;
    } else {
      return true;
    }
  }

  public updateOrder(order: Order) {
    this.setCurrentOrder(order);
    this.calculateOrder();
  }

  private validateItemsOnSchedule() {
    if (this.currentOrder && this.companyService.currentMenu && this.companyService.currentStore) {
      const currentOrder = this.currentOrder;
      const currentStore = this.companyService.currentStore;
      const currentMenu = this.companyService.currentMenu;

      // Find unique groups
      const uniqueGroups = new Map<string, string[]>();
      this.companyService.currentMenu.menus.forEach(({ schedule, groups }) => {
        if (isDateOnSchedule(schedule, currentOrder.date_asked, currentStore.time_zone_offset_minutes)) {
          groups.forEach((group) => {
            uniqueGroups.set(
              group.id,
              group.items.map((item) => item.id)
            );
          });
        }
      });

      const itemsNotFound: string[] = [];
      const allFound = this.currentOrder.items.every((item: IItem) => {
        const group = uniqueGroups.get(item.group_id);
        const found = group && group?.includes?.(item.item_id ?? '');
        if (!found) {
          itemsNotFound.push(currentMenu.items.find((i) => i.id === item.item_id)?.name ?? '');
        }
        return found;
      });
      return {
        valid: allFound,
        message: `The following items are not available for this time:
        ${itemsNotFound.map((item) => `<br/> - ${item}`).join(', ')}
        <br/> Please select a diferent order date and time or remove them from your cart.`,
      };
    } else {
      return {
        valid: false,
        message: '',
      };
    }
  }

  private getDevice() {
    const parser = new UAParser();
    const { model, vendor, type } = parser.getDevice();
    const { name: OSName, version: OSVersion } = parser.getOS();
    const { name: browserName, version: browserVersion } = parser.getBrowser();
    return {
      model: model ?? '',
      vendor: vendor ?? '',
      type: type ?? 'desktop',
      browserName: browserName ?? '',
      browserVersion: browserVersion ?? '',
      OSName: OSName ?? '',
      OSVersion: OSVersion ?? '',
    };
  }

  private setCurrentOrderDevice() {
    if (this.currentOrder) {
      const newOrder = new Order({
        ...this.currentOrder,
        device: this.getDevice(),
      } as IOrder);
      this.setCurrentOrder(newOrder);
    }
  }

  private setCurrentOrder(order: Order | null, triggerValidation: boolean = true) {
    if (order && !order.order_type && this.currentOrderType) {
      order.order_type = this.currentOrderType;
    }
    this._currentOrder.next(order);
    if (order && triggerValidation) {
      this.validate$.next(order);
    }
  }

  private getOrderInLocalStorage(): Order | null {
    this.storageKey = orderKey + '-' + this.companyService.currentMenu?.storeId;
    const localOrder = localStorage.getItem(this.storageKey);
    let order = null;
    try {
      if (localOrder) order = new Order(JSON.parse(localOrder));
    } catch {
      order = null;
    }
    return order;
  }

  private getDefaultOrderType(storeUtils: StoreUtils, order: Order): Partial<Order> {
    const orderTypes = Object.values(OrderType);
    let foundDefault = false;
    let defaultOption: Partial<Order> = {};
    const check = (orderType: OrderType) => {
      if (order) {
        const { available, asapAndDateAsked } = order.isOrderTypeAvailable(orderType, storeUtils, order);
        this.currentOrderType = order.order_type;
        if (available) {
          foundDefault = true;
          defaultOption = asapAndDateAsked;
        }
      }
    };

    if (this.currentOrderType) {
      check(this.currentOrderType);
    } else if (this.currentOrder) {
      check(this.currentOrder.order_type);
    }

    orderTypes.forEach((orderType) => {
      if (!foundDefault) {
        check(orderType);
      }
    });

    return defaultOption;
  }

  private async createLocalOrder() {
    // Empty Order
    const newOrder = new Order({
      id: '',
      pos_order_id: '',
      order_type: this.currentOrderType ?? OrderType.PICKUP,
      is_asap: true,
      scheduled_time: moment.utc().format(),
      is_favorite: false,
      date_asked: moment.utc().format(),
      notes: '',
      store_name: this.companyService.currentStore?.name ?? '',
      subtotal: 0,
      tax: 0,
      appliedDiscounts: [],
      discounts: 0,
      total: 0,
      delivery_fee: 0,
      pending_payment: false,
      tip: 0,
      items: [],
      status: OrderStatus.Created,
      OrderStatus: OrderStatus.Created,
      address: this.currentOrder?.address ?? null,
      tax_rate: this.companyService.currentStore?.tax_rate ?? '0',
      store_address: this.companyService.currentStore?.address ?? null,
      customer: null,
      party_table: null,
    });

    const storeUtils = await firstValueFrom(this.companyService.currentStoreUtils$.pipe(first()));
    const asapAndDateAsked = this.getDefaultOrderType(storeUtils, newOrder);
    this.setCurrentOrder(new Order({ ...(newOrder as IOrder), ...asapAndDateAsked }));
  }

  private createOrder() {
    return firstValueFrom(
      this.http.post<ResponseObj<{ Result: Order }>>(`order/New/${this.companyService.currentMenu?.storeId}`, {}).pipe(
        switchMap((response) => {
          let newOrder = response.Data.Result;
          return this.companyService.currentStoreUtils$.pipe(
            first(),
            map((storeUtils) => {
              const asapAndDateAsked = this.getDefaultOrderType(
                storeUtils,
                new Order({ ...newOrder, is_asap: true } as IOrder)
              );
              return new Order({
                ...newOrder,
                ...asapAndDateAsked,
                items: newOrder.checks?.[0].Selections ?? [],
                appliedDiscounts: [],
                address: this.currentOrder?.address ?? null,
              } as IOrder);
            })
          );
        }),
        catchError(() => {
          this.createLocalOrder();
          return of(this.currentOrder as Order);
        })
      )
    );
  }

  private listenToChangeAndValidate() {
    if (!this.validateSub) {
      this.validateSub = this.validate$
        .pipe(
          switchMap((order) => {
            if (this.authenticated && order?.items?.length > 0 && !this.loadingMenusFromAggregator) {
              const { valid, message } = this.validateItemsOnSchedule();
              if (!valid && !this.validatingOrder) {
                this.toastr.warning(message, undefined, { enableHtml: true });
              }
              this._validatingOrder.next(true);
              if (this.currentOrder) {
                if (this.currentOrder.order_type === OrderType.DELIVERY) {
                  this.setDeliveryServiceCharges(this.currentOrder);
                } else {
                  this.currentOrder.removeDeliveryServiceCharges();
                }
              }
              // Override last payment
              // let Payments: IPayments[] = [];
              // const check = order.checks?.[0];
              // if (check && check?.Payments?.length > 1) {
              //   const firstPayments = check.Payments.slice(0, check.Payments.length - 1);
              //   Payments = [...firstPayments];
              // }
              // Payments.push({
              //   ...(order.getLastPayment() ?? {}),
              //   TipAmount: order.tip,
              // } as IPayments);

              // Verificar se há algo em OrderService.serviceCharges
              let appliedServiceCharges = this.currentOrder?.checks?.[0]?.AppliedServiceCharges ?? null;
              if (OrderService.serviceCharges.length > 0) {
                appliedServiceCharges = [...OrderService.serviceCharges];
                // OrderService.serviceCharges = [];
              }
              const orderAux = {
                ...order,
                CompanyGroupId: this.companyService.currentCompany?.groupId,
                checks: [
                  {
                    DisplayNumber: order.checks?.[0]?.DisplayNumber,
                    AppliedServiceCharges: appliedServiceCharges,
                    AppliedDiscounts: this.currentOrder?.getCheckDiscounts(
                      this.companyService.currentStore?.id,
                      this.companyService.currentCompany?.company_id
                    ),
                    Selections: this.currentOrder?.getSelectionWithDiscount(order),
                    Customer: this.customerService.customer,
                    Payments: [
                      {
                        ...(order.getLastPayment() ?? {}),
                        TipAmount: order.tip,
                      } as IPayments,
                    ],
                  },
                ],
              };
              const route = this.shouldUseAggregator()
                ? 'order/super/calculate'
                : `order/calculate/${this.companyService.currentStore?.id}`;
              return this.http.post<ResponseObj<{ Result: Order }>>(`${route}`, orderAux).pipe(
                first(),
                map((response) => {
                  this._validatingOrder.next(false);
                  return response.Data.Result;
                }),
                catchError((response: HttpErrorResponse) => {
                  this._validatingOrder.next(false);
                  return of(
                    new Order({
                      status: -1,
                      resp_messages: [response.error?.title],
                    } as unknown as IOrder)
                  );
                })
              );
            } else {
              this.calculateOrder();
              this.saveCart();
              return EMPTY;
            }
          })
        )
        .subscribe((_order) => {
          const order = new Order(_order);
          if (order?.isInAValidStatus()) {
            const check = order.checks?.[0];
            const newOrder = new Order({
              ...this.currentOrder,
              ...order,
              status: order.status,
              items: check?.Selections ?? [],
              IdempotencyKey: this.currentOrder?.IdempotencyKey ?? `${new Date().toISOString()}-${Math.random()}`,
              subtotal: check?.Subtotal ?? order.subtotal,
              total: check?.TotalAmount ?? order.total,
              tip: order.getLastPayment()?.TipAmount ?? order.tip ?? this.currentOrder?.tip,
              tax: check?.TaxAmount ?? order.tax,
              discounts: check?.DiscountAmount ?? order.discounts,
              customer: this.customerService.customer,
            } as IOrder);
            this._currentOrder.next(newOrder);
          } else {
            logger.error(order?.resp_messages?.[0]);
            this.toastr.error(
              "We're having problems to process your order right now, please try it again later.",
              'Oops!'
            );
            this.calculateOrder();
          }
          this.saveCart();
        });
    }
  }

  private calculateOrder() {
    if (this.currentOrder?.items?.length) {
      this._currentOrder.next(this.currentOrder?.calculateOrder());
    }
  }

  private saveCart() {
    if (this.currentOrder && this.storageKey) {
      localStorage.setItem(this.storageKey, JSON.stringify(this.currentOrder));
      if (this.startAggregator) {
        this.aggregatorService.saveCartInCookies(
          this.currentOrder,
          this.companyService.currentCompany?.company_id as string,
          this.companyService.currentStore?.id as string
        );
      }
    }
  }

  public saveOrderToCookieAndStartAggregator() {
    this.startAggregator = true;
    if (this.currentOrder && this.companyService.currentCompany && this.companyService.currentStore)
      this.aggregatorService.saveCartInCookies(
        this.currentOrder,
        this.companyService.currentCompany?.company_id,
        this.companyService.currentStore?.id
      );
  }

  public async setOrderFromCookieIfFound() {
    if (this.currentOrder) this.setCurrentOrder(await this.aggregatorService.getOrderFromCookies(this.currentOrder));
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
    this.validateSub?.unsubscribe();
    this.saveCart();
  }
}
