// tslint:disable: member-ordering
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { CSS, defaultTheme, ThemeConfig } from '../model/theme-config';
import { CompanyService } from '@app/@core/services/company.service';
import { Logger } from '@app/@core/services/logger.service';
import { ControledByMaestro } from '@app/@core/services/maestro.service';
import { getSubdomain, notNull } from '@app/@shared/util-functions';
import { environment } from '@env/environment';
import { Theme } from '@app/@core/models/theme.model';

const logger = new Logger('ThemeService');
@Injectable({
  providedIn: 'root',
})
export class ThemeService implements ControledByMaestro, OnDestroy {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private companyService: CompanyService
  ) {
    this.subs.add(
      this.companyService.currentStore$.subscribe((store) => {
        if (store) {
          const storeTheme = this.getStoreTheme(store.id);
          if (storeTheme?.[0] && storeTheme?.[0]?.CustomizationObject) {
            const customizationObject = storeTheme?.[0]?.CustomizationObject as ThemeConfig;
            this._theme.next(customizationObject);
          } else {
            this.subs.add(
              this.getCompanyTheme().subscribe((companyTheme) => {
                this._theme.next(companyTheme);
              })
            );
          }
        }
      })
    );
  }

  private subs: Subscription = new Subscription();

  private readonly _theme = new BehaviorSubject<ThemeConfig | null>(null);
  private readonly _previewing = new BehaviorSubject<Theme | null>(null);

  readonly theme$ = this._theme.asObservable();
  readonly previewing$ = this._theme.asObservable();

  private fetchRequest$: Observable<ThemeConfig> | null = null;
  private storeFetchRequest$: Observable<ThemeConfig> | null = null;

  public get theme() {
    return this._theme.getValue() || defaultTheme;
  }

  public set theme(theme: ThemeConfig | null) {
    this._theme.next(theme);
  }

  public get previewing() {
    return this._previewing.getValue();
  }

  public set previewing(themeId: Theme | null) {
    this._previewing.next(themeId);
  }

  public themeList: Theme[] | null = null;

  public setPreviewingTheme(theme: Theme) {
    this._theme.next(theme.CustomizationObject as ThemeConfig);
    this._previewing.next(theme);
    this.saveLocalStorage(theme.CustomizationObject as ThemeConfig);
  }

  public init() {
    logger.info('Init');
    Object.keys(defaultTheme).forEach((key: string) =>
      this.createCSSVariables(defaultTheme[key], key as keyof ThemeConfig)
    );
    this.subs.add(
      this.companyService.currentCompany$.subscribe((currentCompany) => {
        if (currentCompany) {
          this._theme.next(currentCompany.theme);
        }
      })
    );
    this.subs.add(
      this.getCompanyTheme().subscribe((companyTheme) => {
        this._theme.next(companyTheme);
      })
    );
    this.subs.add(
      this._theme.subscribe((customerTheme) => {
        if (customerTheme) {
          this.setGeneralCSS(customerTheme.general);
          this.setTextCSS(customerTheme.text);
          this.setButtonCSS(customerTheme.button);
          this.setTabCSS(customerTheme.tab);
        }
      })
    );
  }

  public setGeneralCSS(general: ThemeConfig['general']) {
    this.createCSSVariables(general, 'general');
  }

  public setTextCSS(text: ThemeConfig['text']) {
    if (text.css) {
      this.createCustomCSS(text.css, 'Text');
    }
    if (text?.fontFamily) {
      const fontFamily = text.fontFamily;
      this.setFont(fontFamily);
    }
    this.createCSSVariables(text, 'text');
  }

  public setFont(fontFamily: string) {
    return new Promise((resolve, reject) => {
      const WebFont = (window as any).WebFont;
      if (WebFont) {
        WebFont.load({
          google: {
            families: [`${fontFamily}:300,400,600,700`],
          },
          active: () => {
            this.document.documentElement.style.setProperty('--textFontFamily', fontFamily);
            resolve(true);
          },
          inactive: () => {
            reject(true);
          },
        });
      }
    });
  }

  public setButtonCSS(button: ThemeConfig['button']) {
    if (button) {
      if (button.css) {
        this.createCustomCSS(button.css, 'Button');
      }
      this.createCSSVariables(button, 'button');
    }
  }

  public setTabCSS(tab: ThemeConfig['tab']) {
    if (tab.css) {
      this.createCustomCSS(tab.css, 'Tabs');
    }
    if (tab.cssInactive) {
      this.createCustomCSS(tab.cssInactive, 'Tabs-tab');
    }
    if (tab.cssActive) {
      this.createCustomCSS(tab.cssActive, 'Tabs-tab--active');
    }
    this.createCSSVariables(tab, 'tab');
  }

  public createCustomCSS(css: CSS, element: string) {
    const sheet = this.document.createElement('style');
    const base = typeof css === 'string' ? css : css.base ?? '';
    const hover = typeof css === 'string' ? '' : css.hover ?? '';
    sheet.innerHTML = `
      .${element} {
        ${base}
      }
      .${element}:hover {
        ${hover}
      }
    `;
    this.document.head.appendChild(sheet);
  }

  public createCSSVariable = (key: string, value: string) => {
    this.document.documentElement.style.setProperty(`--${key}`, value);
  };

  public getCSSVariable = (key: string) => {
    return this.document.documentElement.style.getPropertyValue(`--${key}`);
  };

  public getCompanyTheme(): Observable<ThemeConfig> {
    if (this.previewing) return of(this.previewing.CustomizationObject as ThemeConfig);
    const subdomain = this.getSubdomain();
    try {
      const companyTheme = localStorage.getItem(`${subdomain}-theme`);
      if (companyTheme && subdomain) {
        return this.getThemeAndCacheIt().pipe(
          map((theme) => {
            if (theme && JSON.stringify(theme) !== companyTheme) {
              this.saveLocalStorage(theme);
              return theme;
            } else {
              return JSON.parse(companyTheme);
            }
          })
        );
      } else if (subdomain) {
        // Company groups use the default theme for now
        if (location.pathname.includes('/company-groups')) return of(defaultTheme);

        return this.getThemeAndCacheIt().pipe(
          map((theme: ThemeConfig | null) => {
            if (theme) {
              this.saveLocalStorage(theme);
              return theme;
            } else {
              return this.theme as ThemeConfig;
            }
          })
        );
      }
    } catch {
      return of(defaultTheme);
    }
    return of(defaultTheme);
  }

  public applyTheme() {
    if (this.previewing) {
      const theme = { ...this.previewing, IsActive: true };
      this.subs.add(
        this.updateTheme(theme).subscribe(() => {
          this.saveLocalStorage(theme.CustomizationObject as ThemeConfig);
          this.themeList?.forEach((_theme) => {
            if (this.previewing?.id !== _theme.id && _theme.IsActive) {
              _theme.IsActive = false;
              this.subs.add(this.updateTheme(_theme).subscribe());
            }
          });
          this._previewing.next(null);
        })
      );
    }
  }

  private getStoreTheme(storeId: string) {
    return this.themeList?.filter((theme) => theme.LocationId === storeId && theme.IsActive);
  }

  public fetchThemesList() {
    const url = this.getBackendUrl();
    return this.http.get<Theme[]>(url, {}).pipe(
      map((themes) => {
        themes.forEach((theme) => {
          theme.CustomizationObject = JSON.parse((theme.CustomizationObject as string).replace('\\', ''));
        });
        themes = themes.filter((theme) => theme.CompanyId == this.companyService.currentCompany?.company_id);
        if (themes.length === 0)
          themes = [
            {
              Label: 'Default',
              CompanyId: this.companyService.currentCompany?.company_id,
              IsActive: true,
              CustomizationObject: defaultTheme,
            } as Theme,
          ];
        this.themeList = themes;
        return themes;
      })
    );
  }

  private getBackendUrl() {
    let url = '';
    let useCache = this.companyService?.useCache ?? false;
    if (useCache) {
      url = `apiCache/customization/Company/${this.companyService.currentCompany?.company_id}`;
    } else {
      url = `customization/Company/${this.companyService.currentCompany?.company_id}`;
    }
    return url;
  }

  public saveLocalStorage(newTheme: ThemeConfig) {
    const subdomain = this.getSubdomain();
    if (subdomain) {
      // Set the newTheme as the active one.
      localStorage.setItem(`${this.getSubdomain()}-theme`, JSON.stringify(newTheme));
    }
  }

  public updateTheme(newTheme: Theme) {
    return this.http.put<Theme>('customization/', newTheme);
  }

  public submitNewTheme(Theme: Theme) {
    return this.http.post<Theme>('customization/', Theme);
  }

  public deleteTheme(ThemeId: string) {
    return this.http.delete(`customization/${ThemeId}`);
  }

  private getThemeAndCacheIt(): Observable<ThemeConfig> {
    if (!this.fetchRequest$) {
      this.fetchRequest$ = this.companyService.currentCompany$.pipe(
        filter(notNull),
        switchMap(() =>
          this.fetchThemesList().pipe(
            map((themes) => {
              const activeThemesList = themes.filter((theme) => theme.IsActive && theme.Level === 'company');
              if (activeThemesList[0]) return activeThemesList[0]?.CustomizationObject as ThemeConfig;
              return defaultTheme; // If the compnay doesn't have a active theme set, it should be the default one.
            })
          )
        )
      );
    }
    return this.fetchRequest$;
  }

  private createCSSVariables = <T extends keyof ThemeConfig>(properties: ThemeConfig[T], defaultKey: T) => {
    const capitalize = (word: string) => word.slice(0, 1).toUpperCase() + word.slice(1);
    Object.entries(properties).forEach(([key, value]) => {
      if (key !== 'css') {
        this.document.documentElement.style.setProperty(
          defaultKey === 'general' ? `--${key}` : `--${defaultKey}${capitalize(key)}`,
          value ?? defaultTheme[defaultKey][key]
        );
      }
    });
  };

  private getSubdomain() {
    return getSubdomain();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
