import { IItem } from '@app/@core/models/item/item.model';
import { Group } from '@app/@core/models/menu/group.model';
import { ModifierGroup } from '@app/@core/models/modifier-group/modifier-group.model';
import * as moment from 'moment';
import { parse, ParsedDomain } from 'psl';
import { ISchedule, Schedule } from '@app/@core/models/schedule.model';
import { Store } from '@app/@core/models/store/store.model';
import { IAddress } from '@app/@core/models/address.model';
import { HttpClient } from '@angular/common/http';
import { identity, Observable, of, UnaryFunction } from 'rxjs';
import { distinctUntilChanged, switchMap, filter, map, catchError, first } from 'rxjs/operators';
import { OrderType, IOrder, OrderStatus } from '@app/@core/models/order/order.model';
import { Weekday } from '@app/@core/models/weekday.model';
import { IZone } from '@app/@core/models/zone.model';
import pointInPolygon, { Point } from 'robust-point-in-polygon';

export function getPeriods(schedule: ISchedule, dayName: Weekday) {
  const weekDay = schedule.schedule_days.find((wd) => wd.day === dayName);
  if (weekDay && weekDay.is_active) {
    const periods =
      weekDay.periods && weekDay.periods.length > 0
        ? weekDay.periods
        : [
            {
              start: weekDay.start ? weekDay.start : schedule.always_available ? '00:00' : '',
              end: weekDay.end ? weekDay.end : schedule.always_available ? '23:59' : '',
            },
          ];
    return periods;
  }
  return [];
}

export function isDateOnSchedule(
  schedule: Schedule,
  dateInUTC: string | moment.Moment,
  tz_offset: number,
  dateOnly?: boolean
): boolean {
  const date = fromUTC(tz_offset, dateInUTC);
  let isDateOk = false;

  if (schedule.always_available) {
    isDateOk = true;
  }

  if (schedule.date_range) {
    const dateFirstHour = date.clone().hour(1);
    const { startDate, endDate } = schedule;
    if (dateFirstHour.isBetween(moment.utc(startDate).startOf('day'), moment.utc(endDate).endOf('day'))) {
      isDateOk = true;
    }
  }
  if (schedule.date_list) {
    isDateOk = schedule.dates.includes(date.format('YYYY-MM-DD'));
  }

  // Backwards compatibility
  if (!isDateOk && !schedule.always_available && !schedule.date_range && !schedule.date_list) {
    const dayName = date.format('ddd');
    const weekDay = schedule.schedule_days.find((wd) => wd.day === dayName && wd.is_active === true);
    if (weekDay) {
      isDateOk = true;
    }
  }

  if (dateOnly) return isDateOk;

  if (isDateOk) {
    const dayName = date.format('ddd');
    const weekDay = schedule.schedule_days.find((wd) => wd.day === dayName);
    if (weekDay && weekDay.is_active) {
      const all_day = weekDay.all_day && (!weekDay.periods || weekDay.periods.length === 0);
      if (all_day) {
        return true;
      } else {
        const periods = getPeriods(schedule, dayName as Weekday);

        return periods.some((period) => {
          const timeStart = moment.utc(period.start, 'HH:mm');
          const timeEnd = moment.utc(period.end, 'HH:mm');
          const time = moment.utc(date.format('HH:mm'), 'HH:mm');
          return time.isBetween(timeStart, timeEnd, 'minutes', '[)');
        });
      }
    } else {
      return false;
    }
  }
  return false;
}

export function isAddressInsideDeliveryZone(address: IAddress, zone: IZone) {
  const polygonPoints = zone.geo_data.split('_');
  const polygon: Point[] = polygonPoints.map((latlng: string) => {
    return latlng.split(',').map((l: string) => parseFloat(l)) as Point;
  });
  const isInside = pointInPolygon(polygon, [address.longitude, address.latitude]) <= 0;
  return isInside;
}

export function isAddressInsideSomeDeliveryZone(address: IAddress, store?: Store) {
  const zones = store?.zones;
  let isInside = false;
  if (zones && zones.length > 0) {
    isInside = zones.some((zone) => {
      const polygonPoints = zone.geo_data.split('_');
      const polygon: Point[] = polygonPoints.map((latlng) => {
        return latlng.split(',').map((l) => parseFloat(l)) as Point;
      });
      return pointInPolygon(polygon, [address.longitude, address.latitude]) <= 0 && !zone.is_disabled;
    });
  }
  return isInside;
}

export function getMinimumOrderingAmount(store: Store, address?: IAddress) {
  let minimumOrderingAmount = Infinity;
  store.zones
    .filter((zone) => !zone.is_disabled)
    .filter((zone) => (address ? isAddressInsideDeliveryZone(address, zone) : true))
    .forEach((zone) => {
      zone.fees
        .filter((fee) => !fee.is_disabled)
        .forEach((fee) => {
          if (fee.min_amount < minimumOrderingAmount) {
            minimumOrderingAmount = fee.min_amount;
          }
        });
    });
  return minimumOrderingAmount === Infinity ? undefined : minimumOrderingAmount;
}

export const notNull = <T>(value: T | null): value is T => value !== null;

export function isItemAvailable(item: IItem, orderType: OrderType, store: Store) {
  const orderingOptionId = store.order_options.find((op) => op.order_type === orderType)?.id;
  const defaultLocationMod = item.location_modifiers?.['0'];
  const locationMod = item.location_modifiers?.[store.id] ?? defaultLocationMod;
  if (orderingOptionId && locationMod?.[orderingOptionId]?.is_disabled === true) {
    return false;
  }
  return true;
}

export function getItemPrice(item: IItem, orderType: OrderType, store: Store) {
  const orderOption = store.order_options.find((op) => op.order_type === orderType);
  if (orderOption) {
    const defaultLocationMod = item.location_modifiers?.['0']?.[orderOption.order_type.toLowerCase()];
    const locationMod = item.location_modifiers?.[store.id]?.[orderOption.id] ?? defaultLocationMod;
    if (locationMod) {
      return locationMod.price_adjustment ?? item.system_price;
    }
  }
  return item.system_price;
}

export function isMenuGroupAvailable(group: Group, orderType: OrderType, store: Store) {
  const orderingOptionId = store.order_options.find((op) => op.order_type === orderType)?.id;
  const defaultLocationMod = group.location_modifiers?.['0'];
  const locationMod = group.location_modifiers?.[store.id] ?? defaultLocationMod;
  if (orderingOptionId && locationMod?.[orderingOptionId]?.is_disabled === true) {
    return false;
  }
  return true;
}

export function isModifierGroupAvailable(modifierGroup: ModifierGroup, orderType: OrderType | undefined, store: Store) {
  if (orderType) {
    const orderingOptionId = store.order_options.find((op) => op.order_type === orderType)?.id;
    const defaultLocationMod = modifierGroup.location_modifiers?.['0'];
    const locationMod = modifierGroup.location_modifiers?.[store.id] ?? defaultLocationMod;
    if (orderingOptionId && locationMod?.[orderingOptionId]?.is_disabled === true) {
      return false;
    }
  }
  return true;
}

export function isModifierGroupItemAvailable(item: IItem, orderType: OrderType | undefined, store: Store) {
  if (orderType) {
    const orderingOptionId = store.order_options.find((op) => op.order_type === orderType)?.id;
    const defaultLocationMod = item.location_modifiers?.['0'];
    const locationMod = item.location_modifiers?.[store.id] ?? defaultLocationMod;
    if (orderingOptionId && locationMod?.[orderingOptionId]?.is_disabled === true) {
      return false;
    }
  }
  return true;
}
(window as any).parse = parse;
export function getSubdomain() {
  const { error, ...parsedDomain } = parse(window.document.location.hostname);
  if (!error) {
    const { subdomain, sld, tld } = parsedDomain as ParsedDomain;
    if (tld === 'localhost') {
      return sld ?? '';
    }
    const firstSubdomain = subdomain?.split?.('.')?.[0] ?? '';
    if (firstSubdomain === 'staging') {
      return '';
    }
    return firstSubdomain;
  } else {
    return '';
  }
}

export function getBaseHost() {
  return window.location.host.split('.').slice(1).join('.');
}

export function fromUTC(offset?: number, utcDate?: string | moment.Moment, format: string | null = null) {
  let originalDate: moment.Moment;

  if (format) {
    originalDate = moment.utc(utcDate, format);
  } else {
    originalDate = moment.utc(utcDate);
  }

  if (originalDate.isValid()) {
    if (offset) {
      return originalDate.add(offset, 'minutes');
    } else {
      return originalDate;
    }
  }

  throw 'Invalid Date';
}

export function toUTC(offset: number, originalDate: string | moment.Moment, format: string | null = null) {
  let utcDate: moment.Moment;

  if (format) {
    utcDate = moment.utc(originalDate, format);
  } else {
    utcDate = moment.utc(originalDate);
  }

  if (utcDate.isValid()) {
    if (offset) {
      return utcDate.subtract(offset, 'minutes');
    } else {
      return utcDate;
    }
  }

  throw 'Invalid Date';
}

export function isGuest() {
  if (sessionStorage.getItem('APP-GUEST') && sessionStorage.getItem('APP-GUEST') === 'true') {
    return true;
  } else {
    return false;
  }
}

export function getDeliveryEstimate(
  order$: Observable<IOrder | null>,
  storeId: string,
  http: HttpClient,
  setLoading?: () => void
) {
  return order$.pipe(
    filter(notNull),
    filter((order) => order.order_type === OrderType.DELIVERY && order.is_asap === true),
    distinctUntilChanged(
      (x, y) => x.address?.latitude === y.address?.latitude && x.address?.longitude === y.address?.longitude
    ),
    first(),
    switchMap((order) => {
      setLoading?.();
      return http.post<{ delivery_time: string; pickup_time: string }>(`delivery/${storeId}/estimate`, order).pipe(
        map(({ delivery_time, pickup_time }) => moment.utc(delivery_time).diff(moment.utc(pickup_time), 'minutes')),
        catchError(() => {
          return of(0);
        })
      );
    })
  );
}
let validating: boolean = false;
let validatePromise: Promise<{ valid: boolean }> | undefined;
export async function isAddressValid(order: IOrder & { address: IAddress }, storeId: string, http: HttpClient) {
  const getKey = (address: IAddress) => address.latitude.toString() + address.longitude.toString();
  const _addressesValidation = sessionStorage.getItem('ADDRESSES-VALIDATION') ?? '{}';
  let addressesValidation: {
    [key: string]:
      | {
          valid: boolean;
        }
      | undefined;
  } = {};
  try {
    addressesValidation = JSON.parse(_addressesValidation);
  } catch {
    addressesValidation = {};
  }
  if (order.address) {
    const key = getKey(order.address);
    let validation = addressesValidation[key];
    if (!validation) {
      if (!validating) {
        let endpoint = 'validate';
        if (!order.checks?.length) endpoint = 'estimate';
        validating = true;
        validatePromise = http
          .post<{ valid: boolean }>(`delivery/${storeId}/${endpoint}`, order)
          .toPromise()
          .then(() => ({ valid: true }))
          .catch(() => ({ valid: false }))
          .finally(() => {
            validating = false;
          });
      } else if (validatePromise) {
        return validatePromise.then(({ valid }) => valid);
      }
      validation = await (validatePromise as Promise<{ valid: boolean }>);
      addressesValidation[key] = validation;
      sessionStorage.setItem('ADDRESSES-VALIDATION', JSON.stringify(addressesValidation));
    }
    if (validation.valid) return Promise.resolve(true);
    else return Promise.resolve(false);
  }
  return Promise.resolve(false);
}

export function currentStatusDescription(order: IOrder): string {
  switch (order.status) {
    case OrderStatus.Created:
      return 'Processing your order';
    case OrderStatus.Enqueued:
      return order.is_asap
        ? 'Sending your order to the restaurant'
        : 'Your order will be sent to the restaurant at the scheduled time';
    case OrderStatus.SentToPos:
    case OrderStatus.WaitingForApproval:
      return 'Restaurant is going to start your order asap';
    case OrderStatus.Confirmed:
      return 'Restaurant is preparing your order';
    case OrderStatus.Ready:
      switch (order.order_type) {
        case OrderType.PICKUP:
        case OrderType.CURBSIDE:
          return 'Order prepared. Waiting for you to pick it up';
        case OrderType.DINE_IN:
          return "Order prepared. You're going to receive it at your table";
        case OrderType.DELIVERY:
          return 'Order prepared. Waiting for the driver to pick it up';
        default:
          return 'Restaunt finished your order';
      }
    case OrderStatus.DriverPickedUp:
      return 'Driver picked your order at the restaurant';
    case OrderStatus.DriverConfirmedConsumerArrival:
      return 'Driver arrived at your address';
    case OrderStatus.Finished:
      return 'Order finished';

    case OrderStatus.DeliveryCancelled:
      return 'Develivery was cancelled';
    case OrderStatus.DeliveryAttempted:
      return 'The driver tried to make contact but the customer was unavailable';

    case OrderStatus.CancellationRequested:
      return 'Order cancellation requested, waiting for the restaurant confirm.';
    case OrderStatus.Cancelled:
      return 'Order cancelled';
  }
  if (order.status >= OrderStatus.ProcessingFailure) {
    return 'Something was wrong with your order, try to contact the restaurant for more information';
  }
  return '';
}

export function calcDistance(firstAddress: { lat: number; lng: number }, secondAddress: { lat: number; lng: number }) {
  // https://stackoverflow.com/a/21623206
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a =
    0.5 -
    c((secondAddress.lat - firstAddress.lat) * p) / 2 +
    (c(firstAddress.lat * p) * c(secondAddress.lat * p) * (1 - c((secondAddress.lng - firstAddress.lng) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a));
}

export function pipe(...fns: Array<UnaryFunction<any, any>>): UnaryFunction<any, any> {
  return pipeFromArray(fns);
}

/** @internal */
export function pipeFromArray<T, R>(fns: Array<UnaryFunction<T, R>>): UnaryFunction<T, R> {
  if (fns.length === 0) {
    return identity as UnaryFunction<any, any>;
  }

  if (fns.length === 1) {
    return fns[0];
  }

  return function piped(input: T): R {
    return fns.reduce((prev: any, fn: UnaryFunction<T, R>) => fn(prev), input as any);
  };
}
